// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---theme-src-gatsby-theme-blog-core-templates-posts-query-js": () => import("./../../theme/src/gatsby-theme-blog-core/templates/posts-query.js" /* webpackChunkName: "component---theme-src-gatsby-theme-blog-core-templates-posts-query-js" */),
  "component---content-pages-about-mdx": () => import("./../content/pages/about.mdx" /* webpackChunkName: "component---content-pages-about-mdx" */),
  "component---content-pages-components-mdx": () => import("./../content/pages/components.mdx" /* webpackChunkName: "component---content-pages-components-mdx" */),
  "component---content-pages-index-mdx": () => import("./../content/pages/index.mdx" /* webpackChunkName: "component---content-pages-index-mdx" */)
}

