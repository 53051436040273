module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.1.44","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{"default":"/opt/build/repo/theme/src/layouts/page.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1300,"linkImagesToOriginal":false,"withWebp":true,"showCaptions":false,"backgroundColor":"transparent","wrapperStyle":"\n                  border-radius: 1rem;\n                  overflow: hidden;\n                ","tracedSVG":{"color":"#c6797ecc","borderRadius":"1rem","turnPolicy":"TURNPOLICY_MAJORITY"}}},"gatsby-remark-smartypants","gatsby-remark-copy-linked-files"],"remarkPlugins":[null]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"josefaidt.dev","icon":"content/assets/images/favicon.png","short_name":"josefaidt.dev","start_url":"/","background_color":"white","theme_color":"#c6797e","display":"minimal-ui"},
    },{
      plugin: require('../../theme/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
